import Keycloak from 'keycloak-js';

declare global {
    interface Window {
        keycloak?: () => Keycloak;
    }
}
export const authService = {
    getIsTokenExpired(): Promise<boolean> | boolean {
        const accessToken = this.getCookie('__Host-AAT');
        const keycloak = window.keycloak && window.keycloak();
        if (!accessToken) {
            return true;
        }

        if (keycloak) {
            const isExpired = keycloak.isTokenExpired(30);

            if (isExpired) {
                const validRefresh = keycloak.updateToken(30).then((refreshed) => refreshed);

                return validRefresh;
            } else {
                return isExpired;
            }
        } else {
            return false;
        }
    },

    getKeycloakAuth(): string | undefined {
        const accessToken = this.getCookie('__Host-AAT');
        const keycloak = window.keycloak && window.keycloak();

        return keycloak && keycloak.token ? keycloak.token : accessToken;
    },

    getRequestAuth(existingHeaders?: { [key: string]: string }): { [key: string]: string } | undefined {
        const accessToken = this.getCookie('__Host-AAT');
        const keycloak = window.keycloak && window.keycloak();

        if (accessToken && keycloak) {
            if (existingHeaders) {
                const newHeader = existingHeaders;
                newHeader['Authorization'] = 'Bearer ' + this.getKeycloakAuth();
                return newHeader;
            }
            return {
                Authorization: 'Bearer ' + this.getKeycloakAuth(),
            };
        } else if (existingHeaders) {
            return existingHeaders;
        }
    },

    getCookie(name: string): string | undefined {
        const cookies = document.cookie.split(';');
        for (let index = 0; index < cookies.length; index++) {
            const cookie = cookies[index].trim();
            const cookieParts = cookie.split('=');
            const cookieName = decodeURIComponent(cookieParts[0]);
            const cookieValue = decodeURIComponent(cookieParts[1]);
            if (cookieName === name) return cookieValue;
        }
    },
};
