import styled from 'styled-components';
import { FormLabel, IconButton, Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';

const Wrapper = styled.div`
    max-width: 343px;
    margin: 0 auto;

    .chakra-input__group {
        background: transparent;
        display: inline-block;
    }

    label {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        color: ${(props) => props.theme.colors.grayDarkTwo};
        margin-bottom: 8px;
    }

    input {
        display: inline-block;
        height: 44px;
        font-size: 16px !important;
        border-radius: 3px;
        background: ${(props) => props.theme.colors.almostWhite};
        border-color: ${(props) => props.theme.colors.grayLightTwo};

        :focus {
            box-shadow: none;
            border-color: ${(props) => props.theme.colors.grayLightTwo};
        }
    }

    .chakra-input__right-element {
        position: absolute;
    }
    .chakra-input__right-element,
    .chakra-input__right-element .chakra-button {
        background: transparent;
        height: 44px;
        margin: 16px 5px auto 0;
        font-size: 16px !important;
        display: block;
        color: ${(props) => props.theme.colors.grayDarkSeven};

        :focus {
            box-shadow: none;
            border-color: ${(props) => props.theme.colors.grayLightTwo};
        }

        svg {
            width: 20px;
            height: 20px;
        }
    }
`;

export default function Search(): JSX.Element {
    return (
        <Wrapper>
            <form action="/searchResults.jsp" method="GET">
                <InputGroup>
                    <FormLabel>Try a new search:</FormLabel>
                    <Input name="searchTerms" />
                    <InputRightElement>
                        <IconButton aria-label="Search" icon={<MagnifyingGlassIcon />} type="submit" />
                    </InputRightElement>
                </InputGroup>
            </form>
        </Wrapper>
    );
}
