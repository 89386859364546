import { ChakraProvider, Spinner } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import styled, { ThemeProvider } from 'styled-components';

import { theme } from '@ee/common-styles';

import useCurrentMember from './hooks/useCurrentMember';
import Search from './components/search';
import Header from './components/header';
import { useEffect, useState } from 'react';
import { authService } from './auth/auth';

const Wrapper = styled.div`
    font-family: 'Nunito Sans', 'Open Sans', sans-serif;
    padding: 50px 0;
    text-align: center;
    max-width: 500px;
    margin: 0 auto;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: ${(props) => props.theme.colors.grayDarkTwo};

    @media screen and (min-width: 769px) {
        padding: 100px 0;
    }
`;

const NoContentSection = styled.div`
    min-height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
`;

const Divider = styled.div`
    display: flex;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    align-items: center;
    text-align: center;
    width: 96%;
    margin: 32px auto;

    hr,
    span {
        display: inline-block;
    }
    hr {
        background-color: ${(props) => props.theme.colors.grayLightTwo};
        border: none;
        flex-grow: 1;
        height: 1px;
    }
    span {
        margin: 0 16px;
        display: inline-block;
        color: ${(props) => props.theme.colors.grayDarkSeven};
        font-size: 12px;
        font-weight: 700;
        line-height: 16px;
        text-align: center;
    }
`;

const HomePageLink = styled.div`
    a {
        font-weight: 700;
        color: ${(props) => props.theme.colors.brandBlue};
    }
`;

function Loading(): JSX.Element {
    return (
        <NoContentSection>
            <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
        </NoContentSection>
    );
}

function NotFound(): JSX.Element {
    const { data: memberResponse, isLoading: isLoadingMember } = useCurrentMember();

    if (isLoadingMember) {
        return <Loading />;
    } else {
        return (
            <Wrapper>
                <Header />
                <Search />
                <Divider>
                    <hr />
                    <span>OR</span>
                    <hr />
                </Divider>
                <HomePageLink>
                    Visit your&nbsp;
                    <a href={memberResponse?.result ? '/dashboard/' : 'https://go.experts-exchange.com/'}>home page</a>.
                </HomePageLink>
            </Wrapper>
        );
    }
}

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

function App(): JSX.Element {
    const [isKeycloakReady, setIsKeycloakReady] = useState(false);

    const waitForGlobal = function (callback: () => void, interval: number) {
        // Waits for 30 seconds for keycloak to be started before sending error
        if (interval > 150) {
            throw new Error("Couldn't authenticate user");
        } else if (window.keycloak) {
            callback();
        } else {
            setTimeout(function () {
                waitForGlobal(callback, interval + 1);
            }, 200);
        }
    };
    useEffect(() => {
        const accessToken = authService.getCookie('__Host-AAT');

        if (accessToken) {
            waitForGlobal(function () {
                setIsKeycloakReady(true);
            }, 0);
        } else {
            setIsKeycloakReady(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
                <ChakraProvider>{isKeycloakReady ? <NotFound /> : <Loading />}</ChakraProvider>
            </ThemeProvider>
        </QueryClientProvider>
    );
}

export default App;
