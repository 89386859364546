import styled from 'styled-components';

import { getCdnDomain } from '@ee/utils';

const Wrapper = styled.div`
    max-width: 343px;
    margin: 0 auto 24px;

    div {
        font-size: 24px;
        font-weight: 700;
        line-height: 24px;
        text-align: center;
        color: ${(props) => props.theme.colors.grayDarkTwo};
        margin-top: 16px;
    }
`;

const Image = styled.img`
    max-width: 100%;
    margin: 0 auto;
`;

export default function Header(): JSX.Element {
    const cdn = getCdnDomain(window.location.host);

    return (
        <Wrapper>
            <picture>
                <source srcSet={`${cdn}/images/experts-exchange/errorPage/notFoundMonitor.webp`} type="image/webp" />
                <Image
                    src={`${cdn}/images/experts-exchange/errorPage/notFoundMonitor.png`}
                    alt={'Image of magnifying glasses over a computer monitor'}
                />
            </picture>
            <div>Oops, page not found.</div>
        </Wrapper>
    );
}
