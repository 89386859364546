import { useQuery, UseQueryResult } from 'react-query';
import { authService } from '../auth/auth';
import { ApiError, MemberResponse } from '../type';

export default function useCurrentMember(): UseQueryResult<MemberResponse, ApiError> {
    return useQuery<MemberResponse, ApiError>(
        'currentMember',
        async () => {
            await authService.getIsTokenExpired();

            return fetch(`/api/v2/members/current/`, {
                headers: authService.getRequestAuth(),
            }).then((res) => res.json());
        },
        { retry: 1, retryDelay: 1000 },
    );
}
